import * as React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Grid from '@material-ui/core/Grid';
import Layout from "../components/layout"
import Seo from "../components/seo"

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class PrivacyPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')   
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = "Privacy Policy"

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName +" "+ siteTitle}  keywords={keywords}/>
        <Layout data={this.props} pagewidth={pagewidth.matches}>        
          <div className="page_wrapper generalpage_wrapper">
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <h1 style={{textAlign: "start"}}>Privacy Policy</h1>
                  </Grid>
                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 1 - What does EdgeForecaster do with your information?</h3>
                    <div className="legalscontent">
                      When a user purchases something from the EdgeForecaster site, as part of this subscription process, EdgeForecaster collect the personal information the user submits, such as name and email address.
                      When a user browses the EdgeForecaster site, anonymised information about the user’s browsing including their computer’s Internet Protocol (IP) address is also recorded. This provides EdgeForecaster with information that helps to learn about a user’s browser and operating system, which ultimately leads to a better product.
                      Email marketing and newsletter (if applicable): With a user’s permission, EdgeForecaster may send them emails about the site, products and other updates.
                    </div>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 2 - Consent</h3>
                    <div className="legalscontent">
                      <span style={{color:"#4648ff"}}>How does EdgeForecaster get a user’s consent?</span> - When a user provides EdgeForecaster with personal information to complete a transaction, verify a credit card, place an order, or any other business-related interaction, EdgeForecaster require that the user consent to the company collecting data and using it for the specific stated reason only.
                      If EdgeForecaster ask a user for their personal information for a secondary reason, like marketing, EdgeForecaster will either ask the user directly for their expressed consent, or provide the user with an opportunity to say no.
                      <br/>
                      <span style={{color:"#4648ff"}}>How does a user withdraw their consent?</span> - If after a user has opted-in, they change their mind, the user may withdraw their consent for EdgeForecaster to contact the user, for the continued collection, use or disclosure of their information, at any time, by contacting EdgeForecaster at hello@edgeforecaster.com or by going to www.edgeforecaster.com/unsubscribe
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 3 - Disclosure</h3>
                    <div className="legalscontent">
                      EdgeForecaster may disclose a user’s personal information if required by law to do so or if a user violates the Terms of Service.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 4 - Payment</h3>
                    <div className="legalscontent">
                      The payment transaction processor used by EdgeForecaster is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) and has been audited by an independent PCI Qualified Security Assessor (QSA) and is certified as a PCI Level 1 Service Provider. This is the most stringent level of certification available in the payments industry.
                      All direct payment gateways adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                      PCI-DSS requirements help ensure the secure handling of credit card information by our site and its service providers.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 5 - Third-Party Services</h3>
                    <div className="legalscontent">
                      In general, the third-party providers used by EdgeForecaster will only collect, use and disclose a user’s information to the extent necessary to allow the third-party provider to perform the services they provide to EdgeForecaster.
                      However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information EdgeForecaster are required to provide to them for a user’s purchase related transactions.
                      For these providers, EdgeForecaster recommend that a user read the third party provider’s privacy policies so the user can understand the manner in which any personal information will be handled by these providers.
                      In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either the user or EdgeForecaster. So if a user elects to proceed with a transaction that involves the services of a third-party service provider, then the user’s information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
                      Once a user leaves the EdgeForecaster website or are redirected to a third-party website or application, the user is no longer governed by this Privacy Policy or the EdgeForecaster website’s Terms of Service.
                      Links - When users click on links on the EdgeForecaster site, the link may direct the user away from the EdgeForecaster site. EdgeForecaster are not responsible for the privacy practices of other sites and encourage users to read the privacy statements the other sites.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 6 - Security</h3>
                    <div className="legalscontent">
                      To protect a user’s personal information, EdgeForecaster take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                      If a user provides the EdgeForecaster payment transaction processor system with their credit card information, the information is encrypted using secure socket layer technology (SSL). Although no method of transmission over the Internet or electronic storage is 100% secure, EdgeForecaster follow all PCI-DSS requirements and implement additional generally accepted industry standards.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 7 - Age of Consent</h3>
                    <div className="legalscontent">
                      By using this site, the user represents that they are at least the age of majority in their state, province or country of residence, or that they are the age of majority in their state, province or country of residence and that the user has given EdgeForecaster their consent to allow any of their minor dependents to use this site.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Section 8 - Changes to this Privacy Policy</h3>
                    <div className="legalscontent">
                      EdgeForecaster reserve the right to modify this privacy policy at any time. Changes and clarifications will take effect immediately upon their posting on the website. If EdgeForecaster make material changes to this policy, EdgeForecaster will notify all registered users that it has been updated, so that they are aware of what information EdgeForecaster collect, how it is used, and under what circumstances, if any, EdgeForecaster use and/or disclose it.
                      If EdgeForecaster is acquired or merged with another company, user information may be transferred to the new owners so that products may still be delivered to users.
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{color:"#4648ff"}}>Questions and Contact Information</h3>
                    <div className="legalscontent">
                      If a user would like to: access, correct, amend or delete any personal information EdgeForecaster have about them, register a complaint, or simply want more information, please contact EdgeForecaster’s Privacy Compliance Officer at hello@edgeforecaster.com.
                    </div>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
      
    )}
}

export default withCookies(PrivacyPage)
export const PrivacyPageQuery = graphql`
  query PrivacyPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
        pluginCreator {
          name
        }
      }
  }
`